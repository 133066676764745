import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUtensils, faCheckSquare } from "@fortawesome/free-solid-svg-icons" */

import SEO from "../../components/seo"

const About = ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="企業案内"
      pagedesc="企業案内"
      pagepath={location.pathname}
      pageimg={data.about.childImageSharp.original.src}
      pageimgw={data.about.childImageSharp.original.width}
      pageimgh={data.about.childImageSharp.original.height}
    />
    <div className="eyecatch">
      <figure>
        <Img
          fluid={data.about.childImageSharp.fluid}
          alt="micro:bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
          loading="eager"
          durationFadeIn={100}
        />
      </figure>
    </div>
    <article className="content">
      <div className="container">

{/*         <h1 className="bar">Iftiny Inc. について</h1>
        <aside className="info">
          <div className="subtitle">
            <FontAwesomeIcon icon={faUtensils} />
            about Iftiny Inc.
          </div>
        </aside> */}

        <div className="inner">
          <h2>Our Vision</h2>
          <br />
          <p>子どもに愛される企業</p>
          <br />
          <br />
        </div>
        <div className="inner">
          <h2>Our Mission</h2>
          <br />
          <p>子どもの笑顔が溢れる環境の実現</p>
          <br />
          <br />
        </div>
        <div className="inner">
          <h2>Our Core Values</h2>
          <br />
          <h3>Be Kind</h3>
          <p>ユーザー・従業員・株主・社会などのすべてに対して、優しさを持って行動する。</p>
          <br />
          <h3>Be Open</h3>
          <p>ユーザー・従業員・株主・社会などのすべてに対して、オープンであり続ける。</p>
          <br />
          <h3>Be Bold</h3>
          <p>果敢にリスクを負う。失敗をしても、原因を分析し、素早くより良くしていく。</p>
          <br />
          <h3>Insight Driven</h3>
          <p>常に本質的な課題を捉え、ユーザーの想像を超えるモノを実現する。</p>
          <br />
          <h3>Technology Driven</h3>
          <p>常に新しいテクノロジーを追求し、イノベーションを起こす。</p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="inner">
          <h2>Company Information</h2>
          <br />
          <p>会社名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：イフティニー株式会社</p>
          <p>会社名英語表記&nbsp;&nbsp;&nbsp;&nbsp;：Iftiny Inc.</p>
          <p>代表取締役&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：斉　暁</p>
          <p>電話&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：022-373-3822</p>
          <p>住所&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：〒981-3133 宮城県仙台市泉区泉中央3丁目27-3 日泉ビル</p>
        </div>
        <br />
        <br />
        <div className="inner">
          <h2>Our Customer</h2>
          <br />
          <p>取引先：国公立学校、独立行政法人国立高等専門学校機構、国公立大学法人、私立学校法人、一般法人など、全国で1000以上</p>
        </div>
        <br />
        <br />
        <div className="inner">
          <h2>Our Store</h2>
          <br />
          <p>
            <Link to={`https://store.iftiny.com`}>micro:bit専門ウェブストア：イフティニー ストア (iftiny store)</Link>
          </p>
        </div>
        <br />
        <br />
        <br />
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query {
    about: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
          height
          width
        }
      }
    }
  }
`
export default About
